<template>
        <v-container fluid>
        <v-row class="text-center">
            <v-col cols="3" sm="2" md="3"></v-col>
            <v-col cols="12" sm="8" md="6">
            <v-card elevation="11" class="pt-10">
                <h2 class="primary--text text-uppercase">{{ title }}</h2>
                <v-divider color="primary" class="mt-2"></v-divider>
                
                <v-alert v-model="showMessage" dismissible
  type="error">
                  
    {{msg}}
                </v-alert>
                <v-form
                @submit.prevent="onLogin"
                ref="form"
                v-model="valid"
                lazy-validation
                class="mt-10 mb-6 pr-8 pl-8 pb-8 pt-4"
                >
                <v-text-field
                    v-model="username"
                    :append-icon="icons.mdiAccountOutline"
                    outlined
                    error-count="2"
                    :rules="usernameRules"
                    label="Username"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :append-icon="show1 ? icons.mdiEye : icons.mdiEyeOff"
                    @click:append="show1 = !show1"
                    :type="show1 ? 'text' : 'password'"
                    label="Password"
                    outlined
                    required
                ></v-text-field>

                <v-btn
                    x-large
                    type="submit"
                    block
                    :loading="pending"
                    :disabled="!valid"
                    color="primary darken-4"
                    class="mr-4 text"
                    @click="validate"
                >
                    <span class="white--text">Login</span>
                </v-btn>
                </v-form>
            </v-card>
            </v-col>
            <v-col cols="3" sm="2" md="3"></v-col>
        </v-row>
        </v-container>
</template>
    
    <script>
    import { mapActions } from "vuex";
    import {mdiEye,mdiAccountOutline,mdiEyeOff} from "@mdi/js";
    export default {
        name:"Login",
        props:{
          redirect: {
            type:String,
            default:"Order Entry"
          }
        },
      data: () => ({
        icons:{mdiEye,mdiAccountOutline,mdiEyeOff},
        title: "Login",
        valid: true,
        show1: false,
        show2: false,
        pending:false,
        username: "",
        usernameRules: [
          v => !!v || "Username is required"
        ],
        password: "",
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length >= 8) || "Password must be less than 8 characters"
        ],
        msg:null
      }),
    
      methods: {
        ...mapActions("User", ["login"]),
        //..validate inputs
        validate() {
          this.refs.form.validate();
        },
        //Login method here
        onLogin() {
          this.msg=null;
          this.pending=true;
          var that=this;
          this.login({username:this.username,pass:this.password})
          .then(r=>{
            if(that.redirect) {
              that.$router.replace({name:that.redirect})
            } else {
              that.$router.replace({name:'Order Entry'})
            }
          })
          .catch(err=>{
            this.msg=err.message;
          })
          .finally(()=>this.pending=false)
        }
      },
      computed:{
        showMessage:{
          get() {return this.msg != null},
          set(v) {if(!v) this.msg=null}
        }
      }
    };
    </script>
    
    <style scoped>
    </style>